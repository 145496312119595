<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel title="Formulir Filter Laporan Realisasi Penerimaan" 
            class="panel panel-success"
        >
            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <!-- <div class="pull-right">
                                <b-button class="pull-right" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button>
                            </div> -->
                            <!-- <h6 class="mb-0">Tools</h6> -->
                            <em class="mb-0">
                                <strong>Form Filter Data Realisasi Penerimaan</strong><br>
                            </em>
                        </template>
                        
                        <!-- FILTER -->
                        <b-card-body class="p-0 m-0">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h5 class="font-weight-bold"><u>IDENTITAS SKPD &amp; UPT</u></h5>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Perangkat</label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-7">
                                            <v-select label="nama" :reduce="nama => nama.id" :options="Perangkat.perangkat"
                                                v-model="params.perangkat_pd_id" @input="inputPerangkat(params.perangkat_pd_id)"
                                                placeholder="-Pilih-" :disabled="user.roles[0].name === 'Bendahara'"></v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Unit</label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-7">
                                            <!-- <v-select label="nama" :reduce="nama => nama.id" :options="Perangkat.unit"
                                                v-model="params.unit_pd_id" placeholder="-Pilih-"
                                                :disabled="user.roles[0].name === 'Bendahara'"></v-select> -->
                                            <v-select label="nama" :reduce="nama => nama.id" :options="Perangkat.unit"
                                                v-model="params.unit_pd_id" placeholder="-Pilih-"></v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-12">
                                    <h5 class="font-weight-bold"><u>PERIODE SSRD</u></h5>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Tahun <span class="text-danger">*</span></label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <v-select :options="tahun" v-model="params.tahun" placeholder="-Pilih Tahun-"></v-select>
                                            <em v-if="errors.hasOwnProperty('tahun')"
                                                class="form-text text-danger">{{ errors.tahun[0] }}</em>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Bulan <span class="text-danger">*</span></label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <!-- <v-select label="nama" :reduce="nama => nama.id" :options="bulan" v-model="params.bulan"
                                                placeholder="-Pilih Bulan-"></v-select> -->
                                            <v-select label="nama" :reduce="nama => nama.id" :options="bulanOptions" v-model="params.bulan"
                                                placeholder="-Pilih Bulan-"></v-select>
                                            <em v-if="errors.hasOwnProperty('bulan')"
                                                class="form-text text-danger">{{ errors.bulan[0] }}</em>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                        <!-- end FILTER -->

                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="getDataLaporan" class="mr-1">
                                    <i class="fa fa-eye"></i> Lihat
                                </b-button>
                                <b-button variant="primary" @click="showPdf">
                                    <i class="fa fa-eye"></i> Dokumen .pdf
                                </b-button>
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->
        </panel>
        <!-- end of panel -->
        <!-- model loader
        -------------------------------------------------- -->
        <b-modal v-model="showLoader" id="modalLoader" hide-footer hide-header no-close-on-backdrop centered size="sm">
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>
        <!-- {{ employee.unit_pd.nama }} -->
        <!-- hasil filter data laporan
        -------------------------------------------------- -->
        <panel v-if="dataLaporan"
            title="Laporan Realisasi Penerimaan" 
            class="panel panel-success"
        >
        
            <div class="row" id="printArea">
            <div class="col-sm-12">

                <!-- KOP SURAT -->
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <td width="15%" align="center"
                                style="background-color:#FFF; border-right-color:#FFF; border-left-color:#FFF;">
                                <img :src="logoPemprovsu" alt="logo" width="50">
                            </td>
                            <td width="70%" class="text-center align-middle"
                                style="background-color:#FFF; border-right-color:#FFF">
                                    <h5 class="title" style="margin-bottom:0px;">
                                        <strong>PEMERINTAH PROVINSI SUMATERA UTARA</strong>
                                    </h5>
                                    <h6 class="title" style="margin-bottom:-5px;">
                                        <strong v-if="employee">{{ employee.unit_pd.perangkat_pd.nama.toUpperCase() }}</strong>
                                    </h6>
                                    <h6 class="mt-2" style="margin-bottom:-2px;">
                                        <small style="font-size:12px; line-height: 1.3;" v-if="employee">
                                            {{ employee.unit_pd.nama.toUpperCase() }}<br>
                                        </small>
                                    </h6>
                            </td>
                            <td width='15%' align='center'
                                style="background-color:#FFF; border-right-color:#FFF; border-left-color:#FFF;">
                            </td>
                        </tr>
                    </thead>
                </table>
                <!-- end KOP SURAT -->

                <!-- JUDUL SURAT -->
                <table id="" class="table table-bordered table-sm">
                    <thead>
                    </thead>
                    <tbody style="font-size:11px; line-height: 1.3;">
                        <tr>
                            <td class="p-0">
                                <table id="" class="m-0 table table-sm">
                                    <tbody style="font-size:11px;">
                                        <tr>
                                            <td colspan="5" class="py-0 px-1" align="center">
                                                <h6 class="title m-0" style="font-size:14px;">
                                                    <strong>
                                                        LAPORAN REALISASI PENERIMAAN
                                                    </strong>
                                                </h6>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="p-0">
                                <table id="" class="m-0 table table-sm">
                                    <tbody style="font-size:11px;">
                                        <tr>
                                            <td colspan="" class="py-0 px-1" width="100px">
                                                Perangkat PD
                                            </td>
                                            <td colspan="" class="py-0 px-1">
                                                <strong v-if="employee">{{ employee.unit_pd.perangkat_pd.nama }}</strong>
                                            </td>
                                            <td colspan="" class="py-0 px-1" width="100px">
                                                Periode
                                            </td>
                                            <td colspan="" class="py-0 px-1" width="150px">
                                                <strong v-if="params">
                                                    {{ params.tahun }} / {{ params.bulan }}
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="" class="py-0 px-1" width="100px">
                                                Unit PD
                                            </td>
                                            <td colspan="" class="py-0 px-1">
                                                <strong v-if="employee">{{ employee.unit_pd.nama }}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="p-0">
                                <table id="" class="m-0 table table-sm">
                                    <thead class="bg-light">
                                        <tr>
                                            <th colspan="2"></th>
                                            <th colspan="4" class="text-center font-weight-bold">
                                                APBD TA. {{ moment().format('YYYY') }}
                                            </th>
                                        </tr>
                                        <tr class="">
                                            <td class="align-middle py-0 px-1">
                                                <strong>Kode Rek.</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1" width="300px">
                                                <strong>Uraian</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1 text-center">
                                                <strong>Target APBD TA. {{ moment().format('YYYY') }}</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1 text-center">
                                                <strong>Sampai dengan {{ moment().locale('id').subtract(1, 'months').format('DD MMMM YYYY') }}</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1 text-center">
                                                <strong>{{ moment().locale('id').format('MMMM') }}</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1 text-center" colspan="2">
                                                <strong>Jumlah</strong>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item) in dataLaporan">
                                            <tr :key="item.id" class="font-weight-bold">
                                                <td>
                                                    {{ item.kode_pd }}
                                                </td>
                                                <td>
                                                    {{ item.nama }}
                                                </td>
                                                <td class="text-right">
                                                    {{ item.total.target === null || 0 ? 0 : item.total.target.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                    <div>
                                                        100&percnt;
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    {{ item.total.sd_bulan_sebelumnya === null || 0 ? 0 : item.total.sd_bulan_sebelumnya.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                    <div>
                                                        {{ item.total.sd_bulan_sebelumnya_persen }}&percnt;
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    {{ item.total.bulan_ini === null || 0 ? 0 : item.total.bulan_ini.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                    <div>
                                                        {{ item.total.bulan_ini_persen }}&percnt;
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    {{ item.total.sd_bulan_ini === null || 0 ? 0 : item.total.sd_bulan_ini.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                    <div>
                                                        {{ item.total.sd_bulan_ini_persen }}&percnt;
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-for="target in item.target" :key="target.id">
                                                <td>
                                                    {{ target.ref_rek_6.kode }}
                                                </td>
                                                <td>
                                                    {{ target.ref_rek_6.nama }}
                                                </td>
                                                <td class="text-right">
                                                    {{ target.apbd === null || 0 ? 0 : target.apbd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                    <div>
                                                        100&percnt;
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    {{ target.realisasi.sd_bulan_sebelumnya === null || 0 ? 0 : target.realisasi.sd_bulan_sebelumnya.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                    <div>
                                                        {{ target.realisasi.sd_bulan_sebelumnya_persen }}&percnt;
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    {{ target.realisasi.bulan_ini === null || 0 ? 0 : target.realisasi.bulan_ini.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                    <div>
                                                        {{ target.realisasi.bulan_ini_persen }}&percnt;
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    {{ target.realisasi.sd_bulan_ini === null || 0 ? 0 : target.realisasi.sd_bulan_ini.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                    <div>
                                                        {{ target.realisasi.sd_bulan_ini_persen }}&percnt;
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </td>
                        </tr>


                    </tbody>
                </table>


                <!-- <pre>{{ dataLaporan }}</pre> -->
                

            </div>
            </div>

        </panel>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import logoPemprovsu from '@/assets/images/logoSumut.png'

import HRetribusi from "@/components/npwr/HRetribusi.vue";
import axios from "axios";
import { mapState } from "vuex";
import hapusStorage from '@/helper/hapusLocalStore'
import moment from 'moment';

export default {
    mixins: [hapusStorage],
    components: {
        HRetribusi
    },
    data() {
        return {
            moment,
            logoPemprovsu: logoPemprovsu,
            user: JSON.parse(localStorage.getItem('user')),
            user_role: JSON.parse(localStorage.getItem('roles'))[0],
            params: new Form({
                perangkat_pd_id: "",
                bulan: "",
                tahun: "",
            }),
            dataLaporan: '',
            bulanOptions: [
                {
                    nama: "Januari",
                    id: 1,
                },
                {
                    nama: "Februari",
                    id: 2,
                },
                {
                    nama: "Maret",
                    id: 3,
                },
                {
                    nama: "April",
                    id: 4,
                },
                {
                    nama: "Mei",
                    id: 5,
                },
                {
                    nama: "Juni",
                    id: 6,
                },
                {
                    nama: "Juli",
                    id: 7,
                },
                {
                    nama: "Agustus",
                    id: 8,
                },
                {
                    nama: "September",
                    id: 9,
                },
                {
                    nama: "Oktober",
                    id: 10,
                },
                {
                    nama: "November",
                    id: 11,
                },
                {
                    nama: "Desember",
                    id: 12,
                },
            ],
            field_favorit: [
                {
                    id: 'total',
                    nama: 'Total'
                },
                {
                    id: 'qty',
                    nama: 'Kuantitas'
                }
            ] ,
            bulan: [],
            tahun: [],
            showLoader: false,
            isLoading: false,
            pdfUrl: "",
            errors: [],
            roles: [
                "Bendahara"
            ],
            employee: null,
            meta: {
                total_penerimaan: 0
            }
        };
    },
    computed: {
        ...mapState(["Perangkat"]),
    },
    mounted() {
        this.$store.dispatch("Perangkat/getPerangkat");
        if (this.roles.includes(this.user_role)) {
            // data pegawai
            this.getPegawai(this.user.pegawai_id)
        }
        this.getTahun();
    },
    methods: {
        inputPerangkat(id) {
            if (id) {
                this.$store.dispatch("Perangkat/getUnit", id);
            } else {
                this.params.unit_pd_id = ''
            }
        },
        // Tahun berjalan
        getTahun() {
            const now = new Date();
            this.tahun.push(String(now.getFullYear()-1));
            this.tahun.push(String(now.getFullYear()));
            this.bulanOptions.forEach((items) => {
                if (now.getMonth() + 1 - 1 === parseInt(items.id)) {
                    this.bulan.push(items);
                } else if (now.getMonth() + 1 === parseInt(items.id)) {
                    this.bulan.push(items);
                } else if (now.getMonth() + 1 + 1 === parseInt(items.id)) {
                    this.bulan.push(items);
                }
            });
            this.params.periode_kolom    = 'diterima_tgl';
            this.params.bulan           = now.getMonth() + 1;
            this.params.tahun           = String(now.getFullYear());
            this.params.metode_bayar_id = "all"
        },
        showPdf() {
            this.getPdf();
        },
        // close modal
        closeModal() {
            this.$root.$emit("bv::hide::modal", "modal-1");
            this.showModalData = false;
            this.pdfUrl = null;
        },
        // get data laporan ...
        getDataLaporan() {
            this.showLoader = true
            axios
                .get("/api/laporan/realisasi/penerimaan", {
                    params: this.params
                })
                .then((response) => {
                    this.showLoader = false
                    const dataRealisasiPenerimaan = response.data.data
                    this.dataLaporan = dataRealisasiPenerimaan.realisasi
                    console.log(this.dataLaporan)
                })
                .catch((error) => {
                    this.showLoader = false
                    this.dataLaporan = ''
                    this.$swal({
                        icon: "error",
                        title: "Terjadi Masalah.",
                        text: error.response.data.status.message,
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonClass: "btn btn-primary",
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 6000
                    });
                });
        },
        // view pdf
        getPdf() {
            this.showLoader = true
            axios
                .get("/api/view-pdf/laporan/transaksi/realisasi/penerimaan", {
                    params: this.params,
                    responseType: "arraybuffer",
                    timeout: 20000,
                })
                .then((response) => {
                    this.showLoader = false
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, '_blank')
                })
                .catch((error) => {
                    this.closeModal();
                    this.showLoader = false
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    }
                });
        },
        // data pegawai
        getPegawai (id) {
            axios.get("/api/data-induk/kepegawaian/pegawai/" + id).then(response => {
                const items = response.data.data
                this.employee = items
                this.params.unit_pd_id = items.unit_pd_id
                this.params.perangkat_pd_id = items.unit_pd.perangkat_pd_id
                this.$store.dispatch("Perangkat/getUnit", this.params.perangkat_pd_id)
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        }
    },
};
</script>

<style scoped>
    .custom-border {
        border-top: 1px solid rgb(78, 77, 77);
    }
</style>