<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel title="Formulir Filter Laporan Realisasi Penerimaan Per Item" 
            class="panel panel-success"
        >
            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <!-- <div class="pull-right">
                                <b-button class="pull-right" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button>
                            </div> -->
                            <!-- <h6 class="mb-0">Tools</h6> -->
                            <em class="mb-0">
                                <strong>Form Filter Data Realisasi Penerimaan Per Item</strong><br>
                            </em>
                        </template>
                        
                        <!-- FILTER -->
                        <b-card-body class="p-0 m-0">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h5 class="font-weight-bold"><u>IDENTITAS SKPD &amp; UPT</u></h5>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Perangkat</label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-7">
                                            <v-select label="nama" :reduce="nama => nama.id" :options="Perangkat.perangkat"
                                                v-model="params.perangkat_pd_id" @input="inputPerangkat(params.perangkat_pd_id)"
                                                placeholder="-Pilih-" :disabled="user.roles[0].name === 'Bendahara'"></v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Unit</label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-7">
                                            <!-- <v-select label="nama" :reduce="nama => nama.id" :options="Perangkat.unit"
                                                v-model="params.unit_pd_id" placeholder="-Pilih-"
                                                :disabled="user.roles[0].name === 'Bendahara'"></v-select> -->
                                            <v-select label="nama" :reduce="nama => nama.id" :options="Perangkat.unit"
                                                v-model="params.unit_pd_id" placeholder="-Pilih-"></v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-12">
                                    <h5 class="font-weight-bold"><u>PERIODE SSRD</u></h5>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Tahun <span class="text-danger">*</span></label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <v-select :options="tahun" v-model="params.tahun" placeholder="-Pilih Tahun-"></v-select>
                                            <em v-if="errors.hasOwnProperty('tahun')"
                                                class="form-text text-danger">{{ errors.tahun[0] }}</em>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Bulan <span class="text-danger">*</span></label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <!-- <v-select label="nama" :reduce="nama => nama.id" :options="bulan" v-model="params.bulan"
                                                placeholder="-Pilih Bulan-"></v-select> -->
                                            <v-select label="nama" :reduce="nama => nama.id" :options="bulanOptions" v-model="params.bulan"
                                                placeholder="-Pilih Bulan-"></v-select>
                                            <em v-if="errors.hasOwnProperty('bulan')"
                                                class="form-text text-danger">{{ errors.bulan[0] }}</em>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Field Favorit <span class="text-danger">*</span></label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <!-- <v-select label="nama" :reduce="nama => nama.id" :options="bulan" v-model="params.bulan"
                                                placeholder="-Pilih Bulan-"></v-select> -->
                                            <v-select label="nama" :reduce="nama => nama.id" :options="field_favorit" v-model="params.sortBy"
                                                placeholder="-Pilih-">
                                            </v-select>
                                            <em v-if="errors.hasOwnProperty('shorBy')"
                                                class="form-text text-danger">{{ errors.bulan[0] }}</em>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-12">
                                    <h5 class="font-weight-bold"><u>RINCIAN RETRIBUSI</u></h5>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label"
                                    >Jenis Jasa / Pelayanan
                                    <span class="text-danger">*</span></label
                                >
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <v-select
                                                label="nama"
                                                :reduce="nama => nama.id"
                                                :options="StepRetribusi.retjenis1Options"
                                                v-model="formRincian.ret_jenis_1_id"
                                                placeholder="Pilih Jenis Jasa / Pelayanan"
                                                @open="onOpenRetJenis1"
                                                @input="inputRetJenis('retjenis1', formRincian.ret_jenis_1_id)"
                                            ></v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label"
                                    >Rincian
                                    <span class="text-danger">*</span></label
                                >
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <v-select
                                                label="nama"
                                                :reduce="nama => nama.id"
                                                :options="StepRetribusi.retjenis2Options"
                                                v-model="formRincian.ret_jenis_2_id"
                                                placeholder="Pilih Rincian"
                                                @input="inputRetJenis('retjenis2', formRincian.ret_jenis_2_id)"
                                            >
                                                <template v-slot:option="option">
                                                    <span v-html="option.nama"></span>
                                                </template>
                                                <template #selected-option="{ nama }">
                                                    <span v-html="nama"></span>
                                                </template>
                                            </v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label"
                                    >Sub Rincian
                                    <span class="text-danger">*</span></label
                                >
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <v-select
                                                label="nama"
                                                :reduce="nama => nama.id"
                                                :options="StepRetribusi.retjenis3Options"
                                                v-model="formRincian.ret_jenis_3_id"
                                                placeholder="Pilih Sub Rincian"
                                                @input="inputRetJenis('retjenis3', formRincian.ret_jenis_3_id)"
                                            ></v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label"
                                    >Parameter
                                    <span class="text-danger">*</span></label
                                >
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <v-select
                                                label="ret_parameter"
                                                :reduce="kode_dh => kode_dh.id"
                                                :options="StepRetribusi.rettarifOptions"
                                                v-model="formRincian.ret_tarif_id"
                                                placeholder="Pilih Parameter"
                                            >
                                                <template v-slot:option="option">
                                                    <span v-html="option.ret_parameter"></span>
                                                </template>
                                                <template #selected-option="{ ret_parameter }">
                                                    <span v-html="ret_parameter"></span>
                                                </template>
                                            </v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                        <!-- end FILTER -->

                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="getDataLaporan" class="mr-1">
                                    <i class="fa fa-eye"></i> Lihat
                                </b-button>
                                <b-button variant="primary" @click="showPdf">
                                    <i class="fa fa-eye"></i> Dokumen .pdf
                                </b-button>
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->
        </panel>
        <!-- end of panel -->
        <!-- model loader
        -------------------------------------------------- -->
        <b-modal v-model="showLoader" id="modalLoader" hide-footer hide-header no-close-on-backdrop centered size="sm">
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>
        <!-- {{ employee.unit_pd.nama }} -->
        <!-- hasil filter data laporan
        -------------------------------------------------- -->
        <panel v-if="dataLaporan"
            title="Laporan Realisasi Penerimaan Per Item" 
            class="panel panel-success"
        >
        
            <div class="row" id="printArea">
            <div class="col-sm-12">

                <!-- KOP SURAT -->
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <td width="15%" align="center"
                                style="background-color:#FFF; border-right-color:#FFF; border-left-color:#FFF;">
                                <img :src="logoPemprovsu" alt="logo" width="50">
                            </td>
                            <td width="70%" class="text-center align-middle"
                                style="background-color:#FFF; border-right-color:#FFF">
                                    <h5 class="title" style="margin-bottom:0px;">
                                        <strong>PEMERINTAH PROVINSI SUMATERA UTARA</strong>
                                    </h5>
                                    <h6 class="title" style="margin-bottom:-5px;">
                                        <strong v-if="employee">{{ employee.unit_pd.perangkat_pd.nama.toUpperCase() }}</strong>
                                    </h6>
                                    <h6 class="mt-2" style="margin-bottom:-2px;">
                                        <small style="font-size:12px; line-height: 1.3;" v-if="employee">
                                            {{ employee.unit_pd.nama.toUpperCase() }}<br>
                                        </small>
                                    </h6>
                            </td>
                            <td width='15%' align='center'
                                style="background-color:#FFF; border-right-color:#FFF; border-left-color:#FFF;">
                            </td>
                        </tr>
                    </thead>
                </table>
                <!-- end KOP SURAT -->

                <!-- JUDUL SURAT -->
                <table id="" class="table table-bordered table-sm">
                    <thead>
                    </thead>
                    <tbody style="font-size:11px; line-height: 1.3;">
                        <tr>
                            <td class="p-0">
                                <table id="" class="m-0 table table-sm">
                                    <tbody style="font-size:11px;">
                                        <tr>
                                            <td colspan="5" class="py-0 px-1" align="center">
                                                <h6 class="title m-0" style="font-size:14px;">
                                                    <strong>
                                                        LAPORAN REALISASI PENERIMAAN PER ITEM
                                                    </strong>
                                                </h6>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="p-0">
                                <table id="" class="m-0 table table-sm">
                                    <tbody style="font-size:11px;">
                                        <tr>
                                            <td colspan="" class="py-0 px-1" width="100px">
                                                Perangkat PD
                                            </td>
                                            <td colspan="" class="py-0 px-1">
                                                <strong v-if="employee">{{ employee.unit_pd.perangkat_pd.nama }}</strong>
                                            </td>
                                            <td colspan="" class="py-0 px-1" width="100px">
                                                Periode
                                            </td>
                                            <td colspan="" class="py-0 px-1" width="150px">
                                                <strong v-if="params">
                                                    {{ params.tahun }} / {{ params.bulan }}
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="" class="py-0 px-1" width="100px">
                                                Unit PD
                                            </td>
                                            <td colspan="" class="py-0 px-1">
                                                <strong v-if="employee">{{ employee.unit_pd.nama }}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="p-0">
                                <table id="" class="m-0 table table-sm">
                                    <thead>
                                        <tr class="text-center">
                                            <td class="align-middle py-0 px-1" width="30px">
                                                <strong>No.</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1" width="300px">
                                                <strong>SKPD</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1">
                                                <strong>Rincian</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1">
                                                <strong>Kuantitas</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1">
                                                <strong>Satuan</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1" colspan="2">
                                                <strong>Total Penerimaan <br> (IDR)</strong>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody v-if="isLoading">
                                        <tr>
                                            <td colspan="6" class="text-center">
                                                <div class="p-5">
                                                   <b-spinner variant="dark" small></b-spinner> Loading...
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(data, index) in dataLaporan" :key="index">
                                            <td class="py-0 px-1 text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="py-0 px-1">
                                                {{ data.perangkat_pd.nama }}
                                            </td>
                                            <td class="py-0 px-1" v-html="data.ret_rincian">
                                            </td>
                                            <td class="py-0 px-1 text-right">
                                                {{ Math.round(data.qty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                            </td>
                                            <td class="py-0 px-1" v-html="data.ret_parameter"></td>
                                            <td class="py-0 px-1 text-right">
                                                {{ Math.round(data.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="text-right">
                                                <strong>Total Realisasi Penerimaan</strong>
                                            </td>
                                            <td class="text-right font-weight-bold">
                                                {{ Math.round(meta.total_qty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                            </td>
                                            <td></td>
                                            <td class="text-right font-weight-bold">
                                                {{ meta.total_penerimaan.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>


                    </tbody>
                </table>
                <!-- <pre>{{ dataLaporan }}</pre> -->
                <!-- BAGIAN PAGINATION -->
                    <b-row>
                        <div class="divider"></div>
                        <b-col md="6" class="my-0">
                            <b-form-group
                                :label-cols="2"
                                label="Per page"
                                class="mb-0"
                            >
                                <b-form inline>
                                    <b-form-select
                                        :options="params.pageOptions"
                                        style="width: 100px;"
                                        v-model="params.perpage"
                                        @input="onInputPageoptions"
                                    />
                                    <label class="ml-1 mr-1">/</label>
                                    <b-form-input
                                        :disabled="true"
                                        v-model="params.totalRows"
                                        style="width: 100px;"
                                        class="text-right"
                                    />
                                    <label class="ml-1">Rows</label>
                                </b-form>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" class="my-0">
                            <b-pagination
                                v-model="params.page"
                                :total-rows="params.totalRows"
                                :per-page="params.perpage"
                                align="fill"
                                size="md"
                                class="my-0 "
                                @input="onInputPagination"
                            ></b-pagination>
                        </b-col>
                    </b-row>
                    <!-- end BAGIAN PAGINATION -->
            </div>
            </div>

        </panel>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import logoPemprovsu from '@/assets/images/logoSumut.png'

import HRetribusi from "@/components/npwr/HRetribusi.vue";
import axios from "axios";
import { mapState } from "vuex";
import hapusStorage from '@/helper/hapusLocalStore'

export default {
    mixins: [hapusStorage],
    components: {
        HRetribusi
    },
    data() {
        return {
            logoPemprovsu: logoPemprovsu,
            user: JSON.parse(localStorage.getItem('user')),
            user_role: JSON.parse(localStorage.getItem('roles'))[0],
            params: new Form({
                perangkat_pd_id: "",
                unit_id: "",
                bulan: "",
                tahun: "",
                sortBy: "qty",
                perpage: 50,
                pageOptions: [25, 50, 100, 200],
                totalRows: 0,
                page: 1
            }),
            dataLaporan: '',
            bulanOptions: [
                {
                    nama: "Januari",
                    id: 1,
                },
                {
                    nama: "Februari",
                    id: 2,
                },
                {
                    nama: "Maret",
                    id: 3,
                },
                {
                    nama: "April",
                    id: 4,
                },
                {
                    nama: "Mei",
                    id: 5,
                },
                {
                    nama: "Juni",
                    id: 6,
                },
                {
                    nama: "Juli",
                    id: 7,
                },
                {
                    nama: "Agustus",
                    id: 8,
                },
                {
                    nama: "September",
                    id: 9,
                },
                {
                    nama: "Oktober",
                    id: 10,
                },
                {
                    nama: "November",
                    id: 11,
                },
                {
                    nama: "Desember",
                    id: 12,
                },
            ],
            field_favorit: [
                {
                    id: 'total',
                    nama: 'Total'
                },
                {
                    id: 'qty',
                    nama: 'Kuantitas'
                }
            ] ,
            bulan: [],
            tahun: [],
            showLoader: false,
            isLoading: false,
            pdfUrl: "",
            errors: [],
            roles: [
                "Bendahara"
            ],
            employee: null,
            meta: {
                total_penerimaan: 0,
                total_qty: 0
            },
            formRincian: {
                ret_jenis_1_id: null,
                ret_jenis_2_id: null,
                ret_jenis_3_id: null,
                ret_tarif_id: null,
            }
        };
    },
    computed: {
        ...mapState(["Perangkat", "StepRetribusi"]),
    },
    mounted() {
        this.$store.dispatch("Perangkat/getPerangkat");
        if (this.roles.includes(this.user_role)) {
            // data pegawai
            this.getPegawai(this.user.pegawai_id)
        }
        this.getTahun();
    },
    methods: {
        inputPerangkat(id) {
            if (id) {
                this.$store.dispatch("Perangkat/getUnit", id);
            } else {
                this.params.unit_pd_id = ''
            }
        },
        // Tahun berjalan
        getTahun() {
            const now = new Date();
            this.tahun.push(String(now.getFullYear()-1));
            this.tahun.push(String(now.getFullYear()));
            this.bulanOptions.forEach((items) => {
                if (now.getMonth() + 1 - 1 === parseInt(items.id)) {
                    this.bulan.push(items);
                } else if (now.getMonth() + 1 === parseInt(items.id)) {
                    this.bulan.push(items);
                } else if (now.getMonth() + 1 + 1 === parseInt(items.id)) {
                    this.bulan.push(items);
                }
            });
            this.params.periode_kolom    = 'diterima_tgl';
            this.params.bulan           = now.getMonth() + 1;
            this.params.tahun           = String(now.getFullYear());
            this.params.metode_bayar_id = "all"
        },
        showPdf() {
            this.getPdf();
        },
        // close modal
        closeModal() {
            this.$root.$emit("bv::hide::modal", "modal-1");
            this.showModalData = false;
            this.pdfUrl = null;
        },
        // add item to object
        addItemToObject: function (sourceObject, destinationObject) {
            const KEY = Object.keys(sourceObject)
            KEY.map(item => {
                destinationObject[item] = sourceObject[item]
            })
        },
        // get data laporan ...
        getDataLaporan() {
            this.isLoading = true
            this.addItemToObject(this.formRincian, this.params)
            axios
                .get("/api/laporan/transaksi/realisasi/item", {
                    params: this.params
                })
                .then((response) => {
                    this.isLoading = false
                    const dataRealisasiPenerimaan = response.data.data
                    this.meta.total_penerimaan = response.data.meta.total
                    this.meta.total_qty = response.data.meta.qty
                    this.params.totalRows = response.data.total
                    this.params.currentPage = response.data.current_page
                    this.dataLaporan = dataRealisasiPenerimaan
                })
                .catch((error) => {
                    this.isLoading = false
                    this.dataLaporan = ''
                    this.$swal({
                        icon: "error",
                        title: "Terjadi Masalah.",
                        text: error.response.data.status.message,
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonClass: "btn btn-primary",
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 6000
                    });
                });
        },
        // view pdf
        getPdf() {
            this.showLoader = true
            axios
                .get("/api/view-pdf/laporan/transaksi/realisasi/item", {
                    params: this.params,
                    responseType: "arraybuffer",
                    timeout: 20000,
                })
                .then((response) => {
                    this.showLoader = false
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, '_blank')
                })
                .catch((error) => {
                    this.closeModal();
                    this.showLoader = false
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    }
                });
        },
        // data pegawai
        getPegawai (id) {
            axios.get("/api/data-induk/kepegawaian/pegawai/" + id).then(response => {
                const items = response.data.data
                this.employee = items
                this.params.unit_pd_id = items.unit_pd_id
                this.params.perangkat_pd_id = items.unit_pd.perangkat_pd_id
                this.$store.dispatch("Perangkat/getUnit", this.params.perangkat_pd_id)
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        },
        // Pagination
        onInputPageoptions: function (event) {
            this.params.perpage = event
            this.getDataLaporan()
        },
        onInputPagination: function (event) {
            this.params.page = event
            this.getDataLaporan()
        },
        resetFormRincian: function (form, params) {
            const KEY = Object.keys(form)
            KEY.map(item => {
                if (!params.includes(item)) {
                    form[item] = null
                }
            })
        },
        onOpenRetJenis1: function () {
            if (this.StepRetribusi.retjenis1Options.length === 0) {
                const pegawai = JSON.parse(localStorage.getItem('pegawai'))
                this.$store.dispatch('StepRetribusi/dataRetJenis1', pegawai.unit_pd.perangkat_pd_id)
            }
        },
        inputRetJenis: function (param, id) {
            switch(param) {
                case 'retjenis1':
                    this.$store.dispatch("StepRetribusi/dataRetJenis2", id);
                    this.resetFormRincian(this.formRincian, ['ret_jenis_1_id'])
                    break
                case 'retjenis2':
                    this.$store.dispatch("StepRetribusi/dataRetJenis3", id)
                    this.resetFormRincian(this.formRincian,
                    ['ret_jenis_1_id', 'ret_jenis_2_id'])
                    break
                case 'retjenis3':
                    this.$store.dispatch("StepRetribusi/dataRetTarif", id)
                    this.resetFormRincian(this.formRincian, ['ret_jenis_1_id', 'ret_jenis_2_id', 'ret_jenis_3_id'])
                    break
                default:
                    this.resetFormRincian()
                    break
            }
        }
    },
};
</script>

<style scoped>
    .custom-border {
        border-top: 1px solid rgb(78, 77, 77);
    }
</style>